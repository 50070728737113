import * as React from "react";
// import TemplateBG from "@/assets/images/TemplateBG.png";

import Layout from "@layout/index";
import Header from "@/components/head";

import {
  Text,
  OrderedList,
  ListItem,
  Box,
  Heading,
  Link as ChakraLink,
  VStack,
  UnorderedList,
  Flex,
} from "@chakra-ui/react";
import customStyles from "@/assets/theme/customStyles";

const { useRef, useState, useEffect, useMemo, memo } = React;

export interface IAffiliateTermsProps {}

export const Head = () => {
  return (
    <Header
      title="Airgram Affiliate Program Terms and Conditions"
      description="This Affiliate Program policy is designed to protect you as an affiliate, Airgram, and the customers. "
    />
  );
};

export const AffiliateTerms: React.FC<IAffiliateTermsProps> = (props) => {
  const {} = props;
  return (
    <Layout
      pt="0px"
      isNewStyle
      px="0px"
      margin="0"
      maxW="100%"
      navMaxW={customStyles.web_max_w}
    >
      <Box
        bgPosition="100% 100%"
        bgSize="cover"
        bgRepeat="no-repeat"
        bgImage="https://a.storyblok.com/f/167495/x/886756942d/meetingtemplatebg.svg"
        p={{
          base: "40px 24px",
          sm: "40px",
          md: "80px 40px",
          lg: "80px 40px",
          xl: "120px 80px",
          "2xl": "160px",
        }}
      >
        <Heading margin="auto" maxW="1200px" as="h1" textAlign="center">
          Airgram Affiliate Program Terms and Conditions
        </Heading>
      </Box>

      <VStack
        pb={{
          base: "40px",
          sm: "64px",
          md: "80px",
          lg: "120px",
          xl: "120px",
          "2xl": "160px",
        }}
        pt="100px"
        margin="auto"
        px={{
          base: "24px",
          sm: "40px",
          md: "40px",
          lg: "40px",
          xl: "80px",
          "2xl": "160px",
        }}
      >
        <Flex
          flexDir="column"
          maxW={`${customStyles.pure_text_max_w}`}
          gap={{
            base: "8px",
            sm: "12px",
          }}
        >
          <Box>
            <Text as="span" marginRight="auto" className="caption">
              Last Updated on August 25th, 2022
            </Text>
          </Box>
          <Text>
            These Terms and Conditions (the “Agreement”) form a legal agreement
            between the individual who accepts these terms (“you” and “the
            Affiliate”) and Airgram Inc. (“Airgram,” “we”, or “us”). Your
            participation in the Airgram Affiliate Program (the “Program”) is
            subject to the terms of this Agreement.
          </Text>
          <Text>
            By clicking on the “Submit” button or “I agree” button, by
            participating in the Airgram Affiliate Program, or by posting or
            sharing an Airgram Affiliate Link, you indicate your agreement to be
            bound by this Agreement.
          </Text>

          <Text as="h2">Definitions</Text>
          <Text>
            The Affiliate Program is designed to give you the opportunity to
            promote the Airgram product to prospective users and be rewarded for
            purchases. Every Affiliate is assigned a unique referral link. The
            “Affiliate Link” is an internet hyperlink or web link pointing to
            https://airgram.io/ and https://app.airgram.io/ (the Website) that
            contains a Partners’ Reference ID.
          </Text>
          <Text as="h2">Usage and Obligations</Text>
          <Text>
            <OrderedList>
              <ListItem>
                When a User clicks on an Affiliate’s link, the Website attempts
                to store the Cookie on the User’s web browser. If the User’s
                browser does not accept cookies, or if the Affiliate Cookie is
                removed or has expired, the User will not be identified as Your
                User.
              </ListItem>
              <ListItem>
                As part of the Program, Airgram may make available to you our
                branding, trademarks, logos, promotional materials, training
                documentation, and other materials related to the Airgrm
                product. The Affiliate does not gain any trademark, copyright,
                or any other rights to these materials.
              </ListItem>
              <ListItem>
                The Affiliate shall agree not to set up any website or social
                media site or buy or bid on names that are Airgram-trademarked,
                including their spelling variations.
              </ListItem>
              <ListItem>
                The Affiliate will make sure all the information shared about
                Airgram and its products is accurate.
              </ListItem>

              <ListItem>
                The Affiliate is not allowed to refer themselves.
              </ListItem>

              <ListItem>
                The Affiliate will not abuse or mislead users (i.e. posting fake
                discounts to coupon-sharing websites).
              </ListItem>

              <ListItem>
                The Affiliate will not use online paid advertising (including
                search engine ads, Facebook ads, and other kinds of ads) to
                promote their Affiliate links.
              </ListItem>

              <ListItem>
                The Affiliate is not authorized to make any commitment on behalf
                of us.
              </ListItem>
              <ListItem>
                We reserve the right to withhold commissions on any transactions
                generated by searches deemed illegal or to block an account that
                is, in the sole determination of Airgram, to be in violation of
                these policies.
              </ListItem>
            </OrderedList>
          </Text>

          <Text as="h2">Commissions and Payment</Text>
          <Text>
            <OrderedList>
              <ListItem>
                For a sale to generate a commission to an Affiliate, the
                customer must complete the upgrade to a paid plan on Airgram
                before the Cookie expires.
              </ListItem>
              <ListItem>
                Commissions will only be paid on sales that are made when the
                customer clicks through qualified, correctly structured
                Affiliate links.
              </ListItem>
              <ListItem>
                Your commissions will be available thirty (30) days after the
                payment is made by a customer.
              </ListItem>

              <ListItem>
                Commissions are paid:
                <UnorderedList>
                  <ListItem>
                    Monthly. Usually on the first week of each month.
                  </ListItem>
                  <ListItem>In United States Dollars ($USD).</ListItem>
                  <ListItem>
                    Via PayPal. In order to receive payment, you must provide a
                    valid PayPal email address.
                  </ListItem>
                </UnorderedList>
              </ListItem>

              <ListItem>
                In the event a customer requests a refund for a transaction for
                which the Affiliate has earned commissions, any commissions
                earned on the refund amount will be deducted from the
                Affiliate’s balance.
              </ListItem>
              <ListItem>
                You shall be responsible for all taxes, duties, VAT charges and
                similar taxes and fees which are levied or imposed by reason of
                Affiliate Fees paid to you.
              </ListItem>
            </OrderedList>
          </Text>

          <Text as="h2">Term and Termination</Text>

          <Text>
            This Agreement will commence upon the date you begin to participate
            in the Program and will continue in effect unless and until
            terminated by you or us.
          </Text>

          <Text>
            You may terminate this Agreement at any time and for any reason by
            sending an email to{" "}
            <ChakraLink color="#9F2AEC" href="mailto:support@airgram.io">
              support@airgram.io
            </ChakraLink>
            . Upon termination (whether by you or by Airgram), all rights and
            licenses granted to you under the Program and this Agreement shall
            immediately terminate and you must remove all references to your
            participation in the Program from any website, social media site, or
            other publicly available advertising or other media.
          </Text>

          <Text>
            We may terminate or suspend your access to all or any part of the
            Program at any time and for any reason or no reason, upon notice and
            without liability.
          </Text>

          <Text as="h2">Modification</Text>
          <Text>
            We may update or modify this Agreement or any referenced policies or
            documents in its sole discretion from time to time. By continuing to
            participate in the Program, you accept any modifications we make to
            this Agreement.
          </Text>
        </Flex>
      </VStack>
    </Layout>
  );
};
export default memo(AffiliateTerms);
