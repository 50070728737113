const customStyles = {
  /**
   * 页面内容最大宽度
   */
  web_max_w: "1280px",
  /**
   * 纯文本页面内容最大宽度
   */
  pure_text_max_w: "900px",
  /**
   * text color
   */
  color_text_white: "#FFFFFF",
  color_text_primary: "#1B1E28",
  color_text_secondary: "#41454B",
  color_text_tertiary: "#7D848E",
  color_text_placeholder: "#9DA3AB",
  color_text_disabled: "#9DA3AB",
};

export default customStyles;
